<script>
    import {data} from '../store';
</script>

<div class="col-12 col-lg-6">
    <p>
        <img class="title-icon" src="../assets/icons/dark/icon-experience.svg" alt="Dark version Experience Icon SVG" />
        <span class="title title--h1 first-title title__separate section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #eb4b28;">{$data.content.resume.section.experience}</span>
    </p>
    <div class="timeline">
        {#each $data.content.jobExperience as experience}
            <!-- Item -->
            <article class="timeline__item mb-2">
                <span class="title title--h5 timeline__title section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #c97d28; line-height: normal;">{experience.title}</span>
                <p class="timeline__subtitle" style="margin-bottom: 0">{`${experience.company} | ${experience.companyLocation}`}</p>
                <span class="timeline__period">{`${experience.startDate} — ${experience.endDate}`}</span>
                <div class="timeline__description">
                    <ul>
                        {#each experience.description as description}
                            <li>{description}</li>
                        {/each}
                    </ul>
                </div>
                <details style="border-bottom: 3px solid #ffb307; padding-bottom: 10px">
                    <summary>{`${experience.technologies.title}`}</summary>
                    <ul>
                        {#each experience.technologies.value as skill}
                            <li>{skill}</li>
                        {/each}
                    </ul>
                </details>
            </article>
        {/each}
    </div>
</div>

<style>
    ul {
        list-style-position: outside;
        padding-left: 20px;
    }
    li {
        padding: 5px;
    }

    details ul {
        margin: 10px;
    }

    details li {
        padding: 0px;
    }
</style>