<script>

    import {data} from '../store';

    import Education from "./Education.svelte";
    import Experience from "./Experience.svelte";
</script>

<div class="pb-3">
    <p class="title title--h1 first-title title__separate section-title blockquote-line">{$data.content.resume.title}</p>
</div>

<div class="pb-0">
    <div class="row">
        <Education />
        <Experience />
    </div>
</div>