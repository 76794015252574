<script>
	import { onMount } from 'svelte';
	import PDFObject from "pdfobject";

	onMount(async () => {

		PDFObject.embed("/assets/pdf/resume.pdf", "#resume", {height: "50rem"});

	});
</script>

<div id="resume"></div>
