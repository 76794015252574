<script>
    import { tsParticles } from "tsparticles-engine";
    import { loadFull } from "tsparticles";

    let particlesConfig = {
        particles: {
            number: {
                value: 355,
                density: {
                    enable: true,
                    value_area: 789.1476416322727
                }
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
                stroke: {
                    width: 0,
                    color: "#000000"
                },
                polygon: {
                    nb_sides: 5
                },
                image: {
                    src: "images/github.svg",
                    width: 100,
                    height: 100
                }
            },
            opacity: {
                value: 0.48927153781200905,
                random: false,
                anim: {
                    enable: true,
                    speed: 0.2,
                    opacity_min: 0,
                    sync: false
                }
            },
            size: {
                value: 2,
                random: true,
                anim: {
                    enable: true,
                    speed: 2,
                    size_min: 0,
                    sync: false
                }
            },
            line_linked: {
                enable: false,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1
            },
            move: {
                enable: true,
                speed: 0.2,
                direction: "none",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        },
        retina_detect: true
    };

    async function loadParticles() {

        await loadFull(tsParticles);

        await tsParticles.load("tsparticles-star", particlesConfig);

        console.log(tsParticles);

    }

    loadParticles();

</script>

<div id="tsparticles-star"></div>