import { readable } from 'svelte/store';
import { linkedin, envelope, phoneSquare, paragraph, language } from 'svelte-awesome/icons';


export const data = readable({
    sidebar: {
        nickName:'Gavin',
        fullName:'Tan Kin Kit',
        jobPosition:"Full Stack Software Developer",
        dob:"October 16, 1997",
        country:"Seremban, Malaysia",
        email:"tangavin60@gmail.com",
        phoneNo:"+60 126371016",
        socialMedia: [
            {
                link: 'tel:60126371016',
                type: 'library',
                class: phoneSquare
            },
            {
                link: 'mailto:tangavin60@gmail.com/',
                type: 'library',
                class: envelope
            },
            {
                link: 'https://www.linkedin.com/in/kin-kit-tan-716059166/',
                type: 'library',
                class: linkedin
            },
        ],
    },

    menu: [
        {
            name: 'About',
            route: '#about-tab'
        },
        {
            name: 'Resume',
            route: '#resume-tab'
        }
    ],

    content: {
        aboutMe : {
            title: 'About Me',
            paragraph: [
                "Full Stack Software Developer with 3+ experience in designing, developing and implementing applications and solutions using a variety of technologies, and programming languages. Seeking to leverage broad development experience and hands-on technical expertise in a challenging role as full stack developer.",
            ]
        },

        whatImDoing: {
            title: "What I'm Doing",
            items: [
                {
                    title: 'Business Analysis',
                    icon: 'BusinessAnalysis',
                    description: 'Research discipline of identifying business needs and determining solutions to business problems.'
                },
                {
                    title: 'Project Management',
                    icon: 'ProjectManagement',
                    description: 'Use Agile project management to achieve goals and meet success project criteria before the deadline.'
                },
                {
                    title: 'System Design',
                    icon: 'SystemDesign',
                    description: 'Design the system architecture, features, UI, and data to satisfy customer requirements.'
                },
                {
                    title: 'System Development',
                    icon: 'SystemDevelopment',
                    description: 'Develop the proposed system with good quality and using the relevant and latest technology.'
                },
            ]
        },

        skills: {
            title: "Skills",
            items: [
                {
                    param: 'Backend',
                    filterClass: 'category-backend',
                    items: [
                        {
                            value: 'PHP'
                        },
                        {
                            value: 'Laravel'
                        },
                        {
                            value: 'Node JS'
                        },
                        {
                            value: 'MySQL'
                        },
                        {
                            value: 'DynamoDB'
                        },
                    ]
                },
                {
                    param: 'Frontend',
                    filterClass: 'category-frontend',
                    items: [
                        {
                            value: 'HTML/CSS',
                        },
                        {
                            value: 'Javascript',
                        },
                        {
                            value: 'JQuery',
                        },
                        {
                            value: 'VueJS'
                        },
                        {
                            value: 'PWA'
                        },
                    ]
                },
                {
                    param: 'Cloud',
                    filterClass: 'category-cloud',
                    items: [
                        {
                            value: 'AWS'
                        },
                        {
                            value: 'Cloudflare'
                        },
                        {
                            value: 'Firebase'
                        },
                        {
                            value: 'Digital Ocean'
                        },
                        {
                            value: 'Terraform'
                        },
                        {
                            value: 'Serverless Framework'
                        },
                    ]
                },
                {
                    param: 'Automation',
                    filterClass: 'category-automation',
                    items: [
                        {
                            value: 'PhantomJS'
                        },
                        {
                            value: 'CasperJs'
                        },
                    ]
                },
                {
                    param: 'Tools',
                    filterClass: 'category-tools',
                    items : [
                        {
                            value: 'Docker'
                        },
                        {
                            value: 'Git'
                        },
                        {
                            value: 'OutSystems'
                        },
                    ]
                },
            ]
        },

        resume: {
            title: 'Resume',
            section: {
                education: 'Education',
                experience: 'Experience'
            }
        },

        education: [
                {
                    title: 'universities',
                    content: {
                        name: 'INTI International University & Colleges',
                        degree: "Bachelor's degree",
                        fieldOfStudy: 'Computer Software Engineering',
                        startDate: '2016',
                        endDate: '2019',
                        description: 'Major in Software Engineer. Completed coursework in statistics, programming, computer information systems, and software planning. CGPA - 3.2.',
                        technologies: {
                            title: 'Skills Learned',
                            value: [
                                'Assembly language',
                                'Java',
                                'ASP.net-Visual Basic',
                                'SQL',
                                'Linux',
                            ]
                        }
                    }
                },
                {
                    title: 'internShips',
                    content: {
                        title: 'Junior Web Developer ( Internship )',
                        company: 'Atos Services (M) Sdn Bhd',
                        companyLocation: 'CyberJaya, Malaysia',
                        startDate: 'Aug 2018',
                        endDate: 'Dec 2018',
                        description: [
                            'Used the Outsystems low code platform to develop mobile or web applications.',
                            'Involved in developing the system features according to user stories given.',
                            'Troubleshoot the root cause and fixed the defects of the system applications.',
                        ],
                        technologies: {
                            title: 'Technologies Used',
                            value: [
                                'Outsystems platform',
                                'Javascript / HTML/ CSS',
                                'Entity Framework  (C# - ASP.net)',
                            ]
                        }
                    },
                }
        ],

        jobExperience: [
            {
                title: 'Senior Software Engineer',
                company: 'Intex Networking Sdn Bhd',
                companyLocation: 'Selangor, Malaysia',
                startDate: 'Jan 2020',
                endDate: 'April 2022',
                description:[
                    'Designed and implemented the reliable, scalable and fault-tolerant applications such as e-commerce and chatbot with utilizing a range of technologies, including Laravel, VueJs, AWS, Cloudflare for clients.',
                    'Ensured applications security and ability to interact with multiple APIs and databases.',
                    'Troubleshoot production processes to identify the root cause of problems and effectively resolved the issue preventing a work stoppage.',
                    'Utilized software engineering expertise to develop applications throughout the software lifecycle to boost business efficiency, from ideation and requirement definition through to development and success deployment.'
                ],
                technologies: {
                    title: 'Technologies Used',
                    value: [
                        'PHP/ Laravel (7.0 - 8.0)',
                        'VueJs + NuxtJs ( I18n.js / Vuetify / Vue Router / Vuex Store Management)',
                        'MySQL/ NoSQL',
                        'HTML / CSS / Javascript /Jquery',
                        'AWS (EB/ EC2 /RDS /S3/ DynamoDB /Lambda/ SNS / API Gateway)',
                        'Cloudflare',
                        'Terraform',
                        'Serverless Framework',
                        'Bash/Shell Scripting',
                        'PhantomJS + CasperJs',
                        'Sentry',
                    ]
                }
            },
            {
                title: 'Junior Software Engineer',
                company: 'Intex Networking Sdn Bhd',
                companyLocation: 'Selangor, Malaysia',
                startDate: 'April 2019',
                endDate: 'December 2019',
                description: [
                    'Designed and implemented the frontend prototype application with VueJs.',
                    'Involved in developing the client CRM system application with Laravel and VueJs.',
                    'Involved in developing and revamping the robot automation script with PhatomJs and CasperJs.',
                    'Involving in setting up the system applications for clients and styling the client frontend based on the graphic designer visual.',
                    'Integrated the FCM and 3rd party mini game services into the client e-commerce application and provided the mini game report and simulation service to analyze and process the data based on their configuration for clients.',
                    'Documented all developed system applications to train the new or existing team members.',
                ],
                technologies: {
                    title: 'Technologies Used',
                    value: [
                        'PHP/ Laravel (7.0 - 8.0) / Yii2',
                        'VueJs ( I18n.js / Vuetify / Vue Router / Vuex Store Management)',
                        'MySQL',
                        'HTML/ CSS / Javascript /Jquery',
                        'AWS (EB/ RDS / S3)',
                        'Firebase Cloud Messaging (FCM)',
                        'Cloudflare',
                        'Create.js',
                        'PhantomJS + CasperJs',
                    ]
                }
            }
        ]
    }
});