<script>
    import {data} from '../store';
</script>

<div class="col-12 col-lg-6">
    <p>
        <img class="title-icon" src="../assets/icons/dark/icon-education.svg" alt="Dark version Education Icon SVG" />
        <span class="title title--h1 first-title title__separate section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #d72f36;">{$data.content.resume.section.education}</span>
    </p>
    <div class="timeline">
        {#each $data.content.education as item}

            {#if item.title == 'universities'}

                <article class="timeline__item">
                    <span class="title title--h5 timeline__title section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #c97d28; line-height: normal;">{item.content.name}</span>
                    <p class="timeline__subtitle" style="margin-bottom: 0">{`${item.content.degree}, ${item.content.fieldOfStudy}`}</p>
                    <p class="timeline__period" style="margin-bottom: 0">{`${item.content.startDate} — ${item.content.endDate}`}</p>
                    <p class="timeline__description">{item.content.description}</p>
                    <details style="border-bottom: 3px solid #ffb307; padding-bottom: 10px">
                        <summary>{`${item.content.technologies.title}`}</summary>
                        <ul>
                            {#each item.content.technologies.value as skill}
                                <li>{skill}</li>
                            {/each}
                        </ul>
                    </details>
                </article>

            {:else if item.title == 'internShips' }

                <article class="timeline__item mt-2">
                    <span class="title title--h5 timeline__title section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #c97d28; line-height: normal;">{item.content.title}</span>
                    <p class="timeline__subtitle" style="margin-bottom: 0">{`${item.content.company} | ${item.content.companyLocation}`}</p>
                    <p class="timeline__period" style="margin-bottom: 0">{`${item.content.startDate} — ${item.content.endDate}`}</p>
                    <div class="timeline__description">
                        <ul>
                            {#each item.content.description as description}
                                <li>{description}</li>
                            {/each}
                        </ul>
                    </div>
                    <details style="border-bottom: 3px solid #ffb307; padding-bottom: 10px">
                        <summary>{`${item.content.technologies.title}`}</summary>
                        <ul>
                            {#each item.content.technologies.value as skill}
                                <li>{skill}</li>
                            {/each}
                        </ul>
                    </details>
                </article>

            {/if}
        {/each}

    </div>
</div>

<style>
    ul {
        list-style-position: outside;
        padding-left: 20px;
    }
    li {
        padding: 5px;
    }

    details ul {
        margin: 10px;
    }

    details li {
        padding: 0px;
    }
</style>
