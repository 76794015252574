<script>
    import {data} from '../store';

    let categrories = setCategories();

    let skills = setSkills();

    function setCategories() {

        let skillState = $data.content.skills.items;

        let temp = ['All'];

        skillState.forEach((skill) => {

            temp.push(skill.param);

        })

        return temp;

    }

    function setSkills() {

        let skillState = $data.content.skills.items;

        let temp = [];

        skillState.forEach((skill) => {

            skill.items.forEach((item) => {

                temp.push({
                    key: skill.param,
                    value: item.value,
                });

            });

        })

        return temp;

    }

</script>

<div class="box-inner" style="padding-top: 0rem">
    <p class="title title--h1 first-title title__separate section-title blockquote-line">{$data.content.skills.title}</p>

    <div class="select">
        <span class="placeholder">Select category</span>
        <ul class="filter">
            <li class="filter__item">Category</li>
            {#each categrories as category, i}
                {#if i == 0}
                    <li class="filter__item active" data-filter="*"><a class="filter__link active" href="#filter">{category}</a></li>
                {:else}
                    <li class="filter__item" data-filter="{`.category-${category}`}"><a class="filter__link" href="#filter">{category}</a></li>
                {/if}
            {/each}
        </ul>
    </div>

    <div class="col-12">
        <div class="box box__second mb-3">
            <div class="js-filter-container">
                {#each skills as skill}
                    <div class="badge badge--light m-1 {`category-${skill.key}`}">{skill.value}</div>
                {/each}
            </div>
        </div>
    </div>

</div>

<style>
    .category-Backend {
        background-color: #ec4a4a;
        color: #ffffff;
    }

    .category-Frontend {
        background-color: #0a27f9;
        color: #ffffff;
    }

    .category-Cloud {
        background-color: #d69e03;
        color: #ffffff;
    }

    .category-Tools {
        background-color: #e26900;
        color: #ffffff;
    }

    .category-Automation {
        background-color: #06732d;
        color: #ffffff;
    }

</style>


