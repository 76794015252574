<script>
    import {data} from '../store';
    import BusinessAnalysis from "./Icons/BusinessAnalysis.svelte";
    import ProjectManagement from "./Icons/ProjectManagement.svelte";
    import SystemDesign from "./Icons/SystemDesign.svelte";
    import SystemDevelopment from "./Icons/SystemDevelopment.svelte";

    $: components = { BusinessAnalysis, ProjectManagement, SystemDesign, SystemDevelopment };

</script>

<div class="box-inner pb-0">
    <p class="title title--h1 first-title title__separate section-title blockquote-line">{$data.content.whatImDoing.title}</p>
    <div class="row">
        {#each $data.content.whatImDoing.items as items}
            <div class="col-12 col-lg-6">
                <div class="case-item box box__second">
                    <svelte:component this={components[items.icon]}></svelte:component>
                    <div>
                        <span class="title title--h5 section-title blockquote-line" style="box-shadow: inset 0 -34px 0 0 #c97d28; line-height: normal; padding: 0; margin: 0;">{items.title}</span>
                        <p class="case-item__caption" style="margin-top: 1rem; font-size: 0.9rem;">{items.description}</p>
                    </div>
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    .case-item div {
        flex: unset;
    }
</style>