<script>
    import {data} from '../store';
</script>

<div class="pb-0 pb-sm-2">
    <p class="title title--h1 first-title title__separate section-title blockquote-line">{$data.content.aboutMe.title}</p>
    {#each $data.content.aboutMe.paragraph as about}
        <p>{about}</p>
    {/each}
</div>
