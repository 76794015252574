<script>
    import {data} from '../store';
</script>

<div class="circle-menu">
    <div class="hamburger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<div class="inner-menu js-menu js-tabs">
    <ul class="nav">
        {#each $data.menu as item, i}
            <li class="nav__item"><a class="{ i === 0 ? 'active' : ''}" href="{item.route}">{item.name}</a></li>
        {/each}
    </ul>
</div>