<script>
	import { onMount } from 'svelte';
    import { loadScript } from './script.js';

	import Router from 'svelte-spa-router';
	import routes from "./routes";
	import Background from "./Background.svelte";

	onMount(async () => {
		const scripts = ["assets/js/jquery-3.4.1.min.js", "assets/js/plugins.min.js", "assets/js/common.js"];
		for (const source of scripts) {
			await loadScript(source);
		}
    });

</script>

<Background />
<Router {routes} />

<style>
	@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@900&display=swap");

	:global(details[open] summary ~ *) {
		animation: open 0.3s ease-in-out;
	}

	@keyframes open {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	:global(details summary::-webkit-details-marker) {
		display: none;
	}

	:global(details summary) {
		width: 100%;
		padding: 0.5rem 0;
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;
		position: relative;
		cursor: pointer;
		font-weight: 300;
		list-style: none;
	}

	:global(details summary:after) {
		content: "+";
		color: #fff;
		position: absolute;
		line-height: 0;
		margin-top: 0.75rem;
		right: 0;
		font-weight: 200;
		transform-origin: center;
		transition: 200ms linear;
	}

	:global(details[open] summary:after) {
		transform: rotate(45deg);
		font-size: 2rem;
	}

	:global(details summary) {
		outline: 0;
	}

	:global(details p) {
		font-size: 0.95rem;
		margin: 0 0 1rem;
		padding-top: 1rem;
	}

	:global(.section-title) {
		font-family: "Archivo", sans-serif;
		font-weight: 700;
		line-height: 1.3;
	}

	:global(.blockquote-line) {
		display: inline-block;
		padding-right: 20px;
		padding-left: 20px;
		box-shadow: inset 0 -34px 0 0 #0c6f98;
		line-height: 55px;
	}

</style>