<script>
	import { onMount } from 'svelte';
	import Sidebar from "../Components/Sidebar.svelte";
	import Content from "../Components/Content.svelte";

	onMount(async () => {

		if ('serviceWorker' in navigator) {

			window.addEventListener('load', () => {
				navigator.serviceWorker.register('/service-worker.js')
					.then(reg => {
						console.log('Registration succeeded. Scope is ' + reg.scope);
					})
					.catch(registrationError => {
						console.log('SW registration failed: ', registrationError);
					});
			});

		}

	});
</script>

<!-- Preloader -->
<div class="preloader">
	<div class="preloader__wrap">
		<div class="circle-pulse">
			<div class="circle-pulse__1"></div>
			<div class="circle-pulse__2"></div>
		</div>
		<div class="preloader__progress">
			<span></span>
		</div>
	</div>
</div>

<main class="main">
	<div class="container gutter-top">
		<div class="row sticky-parent">
			<Sidebar />
			<Content />
		</div>
	</div>
</main>

