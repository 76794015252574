<script>
    import {data} from '../store';
    import Icon from 'svelte-awesome';

    let isHover = false;

</script>

<aside class="col-12 col-md-12 col-xl-3">
    <div class="sidebar box shadow pb-0 sticky-column">
        <svg class="avatar avatar--180" viewBox="0 0 188 188">
            <g class="avatar__box">
                <image xlink:href="assets/images/avatar/Avatar-4.svg" height="100%" width="100%" />
            </g>
        </svg>
        <div class="text-center">
            <h3 class="title title--h3 sidebar__user-name">
                <span class="weight--500">{$data.sidebar.nickName}</span><br/>
                {$data.sidebar.fullName}
            </h3>
            <div class="badge badge--light">{$data.sidebar.jobPosition}</div>
            <!-- Social -->
            <div class="social">
                {#each $data.sidebar.socialMedia as social}
                <!-- https://stackoverflow.com/questions/17711146/how-to-open-link-in-new-tab-on-html/ -->
                    <a class="social__link" href={social.link} target="_blank" rel="noopener noreferrer">
                        {#if social.type == 'html'}
                            <i class="font-icon {social.class}"></i>
                        {:else if social.type == 'library'}
                            <Icon class="social__link socialIconColor" style="{ isHover ? 'color: #277b94' : 'color: #1060b7'}" data={social.class}/>
                        {/if}
                    </a>
                {/each}
            </div>
        </div>
        <div class="sidebar__info box-inner box-inner--rounded">
            <ul class="contacts-block">
                <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Birthday">
                    <i class="font-icon icon-calendar"></i>{$data.sidebar.dob}
                </li>
                <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Address">
                    <i class="font-icon icon-location"></i>{$data.sidebar.country}
                </li>
                <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="E-mail">
                    <a href="mailto:{$data.sidebar.email}"><i class="font-icon icon-envelope"></i>{$data.sidebar.email}</a>
                </li>
                <li class="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Phone">
                    <i class="font-icon icon-phone"></i>{$data.sidebar.phoneNo}
                </li>
            </ul>
            <a class="btn" target="_blank" rel="noopener noreferrer" href="#/resume" on:mouseenter={() => {isHover = true}} on:mouseleave={() => {isHover = false}}><i class="font-icon icon-download"></i> Download CV</a>
        </div>
    </div>
</aside>


<style>

    .btn {
        color: #fff;
    }

    .btn:hover {
        background-color: #277b94;
    }
</style>